// export const baseurl = "https://py-airetrieval.mobiloitte.io/"; // staging url
export const baseurl = "http://110.172.130.122:8033/";
// export const baseurl = ""; client baseUrl


let user = `${baseurl}api/v1`;
let authDetail = `${baseurl}api/user`;
let admin = `${user}/admin`;
let kyc = `${user}/kyc`;
let staticContent = `${user}/static_content`;
let brand = `${user}/brand`;
let report = `${user}/reportPost`;
let subadmin = `${user}/subAdmin`;
let products = `${user}/product`;
let faq = `${baseurl}api/faq`;
const ApiConfigs = {
  // admin Login

  emailForgotPassword: `${authDetail}/forgotPassword`,
  changePassword: `${authDetail}/changePassword`,
  login: `${user}/admin/login`,
  verifyOTPEmail: `${authDetail}/verifyOtp`,
  resetPassword: `${authDetail}/resetPassword`,
  resendOTP: `${authDetail}/resendOtp`,
  //admin
  dashboard: `${admin}/dashboard`,
  listUser: `${admin}/users-management`,
  
  deleteUser: `${admin}/deleteUser`,

  blockUnblockUser: `${admin}/users-management`,

  viewAdminProfile: `${authDetail}/profile`,
  listBank: `${admin}/listBank`,
  addBank: `${admin}/addBank`,
  editBank: `${admin}/editBank`,
  deleteBank: `${admin}/deleteBank`,
  acitavateBank: `${admin}/acitavateBank`,
  editProfile: `${authDetail}/editProfile`,
  depositeList: `${admin}/depositeList`,
  approveDepositRequest: `${admin}/approveDepositRequest`,
  activityLogHistory: `${admin}/activityLogHistory`,
  ApproveUser: `${admin}/aprove-user`,
  RejectUser: `${admin}/reject-user`,

  //kyc
  listKyc: `${kyc}/kycList`,
  approveKyc: `${kyc}/approveRejectKYC`,
  rejectKyc: `${kyc}/approveRejectKYC`,

  //staticContent
  staticContentList: `${staticContent}/listStaticContent`,
  editStaticContent: `${staticContent}/editStaticContent`,

  //skills category
  listSkill: `${staticContent}/howToPlayCategoryList`,
  addAdminSkill: `${staticContent}/addHowToPlayCategory`,
  deleteSkill: `${staticContent}/deleteHowToPlayCategory`,
  editSkill: `${staticContent}/editHowToPlayCategory`,

  // how to play
  // listHowToPlay: `${staticContent}/howToPlayList`,
  listHowToPlay: `${staticContent}/howToPlayListWithCategory`,
  addHowToPlay: `${staticContent}/addHowToPlay`,
  deleteHowToPlay: `${staticContent}/deleteHowToPlay`,
  editHowToPlay: `${staticContent}/editHowToPlay`,
  viewHowToPlay: `${staticContent}/viewHowToPlay`,
  //report
  reportList: `${report}/reportList`,
  deleteReport: `${report}/deleteReport`,
  blockPost: `${report}/blockPost`,

  //category
  listCategory: `${admin}/getAllBannnersPagination`,
  addCategory: `${admin}/addBanner`,
  editCategory: `${admin}/updateBanner`,
  deleteCategory: `${admin}/deleteBanner`,

  //sub Admin
  addSubAdmin: `${subadmin}/addSubAdmin`,
  updateSubAdmin: `${subadmin}/updateSubAdmin`,

  //brand management
  listBrand: `${brand}/listBrand`,
  addBrand: `${brand}/createBrand`,
  deleteBrand: `${brand}/deleteBrand`,
  editBrand: `${brand}/updateBrand`,
  listAllBrand: `${brand}/listAllBrand`,

  //Organization
  listProducts: `${products}/listProduct`,
  viewProducts: `${products}/viewProduct`,
  addProducts: `${products}/addProduct`,
  editProducts: `${products}/editProduct`,
  addOrganizationType: `${products}/addOrganizationType`,
  updateOrganizationType: `${products}/updateOrganizationType`,

  // faq's
  faqList: `${faq}/faqList`,
  addFAQ: `${faq}/addFAQ`,
  editFAQ: `${faq}/editFAQ`,
  viewFAQ: `${faq}/viewFAQ`,
  deleteFAQ: `${faq}/deleteFAQ`,
};

export default ApiConfigs;
